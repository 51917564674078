<script setup lang="ts">
import Dropzone, { type DropzoneFile } from 'dropzone';
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';
import { useEventBus } from '@vueuse/core';
import { globalKey, STEPPER_FORCE_LOGOUT } from '@utils/events';

const { emit: busEmit } = useEventBus(globalKey);

interface VaporDropzoneProps {
  destroyDropzone?: boolean;
  id?: string;
  includeStyling?: boolean;
  noSlot?: boolean;
  bucket?: string;
  options?: {
    autoProcessQueue?: boolean;
    maxFiles?: number;
    parallelUploads?: number;
    previewsContainer?: boolean;
    thumbnailHeight?: number;
    thumbnailWidth?: number;
    visibility?: string;
    uploadMultiple?: boolean;
  };
  fullscreen?: boolean;
  fileTypes?: string;
}

const props = withDefaults(defineProps<VaporDropzoneProps>(), {
  destroyDropzone: true,
  id: 'dropzone',
  includeStyling: true,
  noSlot: false,
  url: '',
  bucket: '',
  options: undefined,
  fullscreen: false,
  fileTypes: '.jpg,.jpeg,.png'
});

const dropzoneEl = ref<HTMLElement | null>(null);
let dropzone: Dropzone;

const dropzoneOptions = computed(() => {
  return {
    acceptedFiles: props.fileTypes,
    url: '#',
    method: 'put',
    autoQueue: true,
    autoProcessQueue: true,
    timeout: 0,
    parallelUploads: 2,
    ...props.options,
  };
});

const emit = defineEmits([
  'success',
  'successmultiple',
  'sending',
  'thumbnail',
  'added-file',
  'added-files',
  'removed-file',
  'max-files-exceeded',
  'max-files-reached',
  'error',
  'processing',
  'upload-progress',
  'processing-multiple',
  'total-upload-progress',
  'canceled',
  'complete',
  'complete-multiple',
  'extra',
  'drop',
  'dragstart',
  'dragend',
  'dragenter',
  'dragover',
  'dragleave',
  'paste',
  'queue-complete',
]);

const { createPresignedRequest } = useVapor();

const runtimeConfig = useRuntimeConfig();

const awsBucketData = runtimeConfig.public.vapor.awsBucketData;

onMounted(() => {
  if (dropzoneEl.value === null) {
    throw new Error('dropzone is not set');
  }

  dropzone = new Dropzone(dropzoneEl.value, {
    ...dropzoneOptions.value,
    accept: function (
      file: DropzoneFile,
      done: (error?: string | Error) => void,
    ) {
      // By default the maxfiles is 0 for no limit.
      if (this.options.maxFiles && this.files.length > this.options.maxFiles) {
        return;
      }
      createPresignedRequest(file, {
        bucket: props.bucket === '' ? awsBucketData : props.bucket,
        contentType: file.type,
      })
        .then((response) => {
          const headers = response.headers;

          if ('Host' in headers) {
            delete headers.Host;
          }

          this.options.method = 'put';
          this.options.url = response.url;
          this.options.headers = headers;
          file.aws = response;
          file.aws.extension = file.name.split('.').pop();

          return done();
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            busEmit(STEPPER_FORCE_LOGOUT, {
              error,
            });
          } else {
            done(error.message || 'Failed to get signed URL');
          }
        });
    },
    sending: function (file: DropzoneFile, xhr: XMLHttpRequest) {
      xhr.open(this.options.method, file.aws.url, true);

      const _send = xhr.send;
      xhr.send = function () {
        _send.call(xhr, file);
      };

      return true;
    },
  });

  /**
   * Events
   * https://github.com/dropzone/dropzone/blob/main/src/options.js
   */

  dropzone.on('addedfile', (file: DropzoneFile) => {
    emit('added-file', file);
  });

  dropzone.on('thumbnail', (file: DropzoneFile, dataUrl: string) =>
    emit('thumbnail', file, dataUrl),
  );

  dropzone.on('success', (file: DropzoneFile) => {
    emit('success', file);
  });

  dropzone.on('successmultiple', (files: DropzoneFile[]) =>
    emit('successmultiple', files),
  );

  dropzone.on(
    'sending',
    (file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData) =>
      emit('sending', file, xhr, formData),
  );

  dropzone.on('addedfiles', (files: DropzoneFile[]) => {
    if(dropzone.options.maxFiles && dropzone.files.length > dropzone.options.maxFiles) {
      while (dropzone.files.length > dropzone.options.maxFiles) {
        dropzone.removeFile(dropzone.files[dropzone.options.maxFiles]); // Remove the extra file
      }
    }
    emit('added-files', files);
  }
  );

  dropzone.on('removedfile', (file: DropzoneFile) =>
    emit('removed-file', file),
  );

  dropzone.on('maxfilesexceeded', (file: DropzoneFile) =>
    emit('max-files-exceeded', file),
  );

  dropzone.on('maxfilesreached', (files: DropzoneFile[]) =>
    emit('max-files-reached', files),
  );

  dropzone.on('error', (file: DropzoneFile, message: string | Error) =>
    emit('error', file, message),
  );

  dropzone.on('processing', (file: DropzoneFile) => emit('processing', file));

  dropzone.on(
    'totaluploadprogress',
    (totalBytes: number, totalBytesSent: number) =>
      emit('total-upload-progress', totalBytes, totalBytesSent),
  );

  dropzone.on(
    'uploadprogress',
    (file: DropzoneFile, progress: number, bytesSent: number) =>
      emit('upload-progress', file, progress, bytesSent),
  );

  dropzone.on('processingmultiple', (files: DropzoneFile[]) =>
    emit('processing-multiple', files),
  );

  dropzone.on('completemultiple', (files: DropzoneFile[]) =>
    emit('complete-multiple', files),
  );

  dropzone.on('canceled', (file: DropzoneFile) => emit('canceled', file));

  dropzone.on('complete', (file: DropzoneFile) => emit('complete', file));

  dropzone.on('drop', (event: DragEvent) => emit('drop', event));
  dropzone.on('dragstart', (event: DragEvent) => emit('dragstart', event));
  dropzone.on('dragend', (event: DragEvent) => emit('dragend', event));
  dropzone.on('dragenter', (event: DragEvent) => emit('dragenter', event));
  dropzone.on('dragover', (event: DragEvent) => emit('dragover', event));
  dropzone.on('dragleave', (event: DragEvent) => emit('dragleave', event));

  dropzone.on('queuecomplete', () => emit('queue-complete'));
});

onBeforeUnmount(() => {
  if (props.destroyDropzone) dropzone.destroy();
});
</script>

<template>
  <div
    :id="props.id"
    ref="dropzoneEl"
    :class="{
      dropzone: props.includeStyling,
      'fixed left-0 top-0 z-50 h-full w-full': props.fullscreen,
    }"
  >
    <div v-if="!props.noSlot" class="dz-message h-full w-full">
      <slot :dropzone-instance="dropzone">Drop files here to upload</slot>
    </div>
  </div>
</template>
